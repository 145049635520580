export function convertToStringArray(field: null | string | string[]): string[] {
    if (field === null) {
        return [];
    } else if (typeof field === 'string') {
        return [field];
    } else if (Array.isArray(field)) {
        return field;
    } else {
        return [];
    }
}
