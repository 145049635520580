import React from "react";
import className from "classnames";
import CopyrightIcon from "../../../assets/icons/commitment/copyright.png";
import Bitmap1 from "../../../assets/icons/commitment/Bitmap-1.png";
import Bitmap2 from "../../../assets/icons/commitment/Bitmap-2.png";
import Bitmap3 from "../../../assets/icons/commitment/Bitmap-3.png";
import { Box, Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Steps } from "./form-design";

interface IProps {
  step: Steps
}
export default function Commitment(props: IProps): JSX.Element {
  const { step } = props;
  const { t } = useTranslation();

  const datas = [
    {
      title: "faq.Wewillneverown",
      icon: <img src={CopyrightIcon} alt="Copyright" />,
      index: 0,
    },
    {
      title: "faq.Wecannotpublish",
      icon: <img src={Bitmap1} alt="Bitmap1" />,
      index: 3,
    },
    {
      title: "faq.Weownourproduction",
      icon: <img src={Bitmap3} alt="Bitmap3" />,
      index: 2,
    },
    {
      title: "faq.Humanlyandenvironmentally",
      icon: <img src={Bitmap2} alt="Bitmap2" />,
      index: 1,
    },
  ];

  // Dont' display any commitment excep for these two
  if (step != Steps.ART && step != Steps.DESIGNS && step != Steps.INFO)
    return <></>;

  return (
    <div className="commitment">
      <div className={className("content", step && "content--1")}>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <div className="line line--commitment">
            <div></div>
          </div>
        </Box>
        {step === Steps.INFO && (
            <div>
              <div dangerouslySetInnerHTML={{ __html: t('commitment.InfoNextStep') }} />
            </div>
        )}
        {step === Steps.DESIGNS && (
          <div>
            <p>{t("commitment.Designsarefree")}</p>
            <p>{t("commitment.Ourdesignerswill")}</p>
            <p>{t("commitment.Youcanthen")}</p>
            <p>{t("commitment.Thisisnot")}</p>
            <p>{t("commitment.Thatisitfor")}</p>
          </div>
        )}
        {step === Steps.ART && (
          <>
            <p className="commitment__title">{t("faq.Wesupplythousands")}</p>
            <div className="row">
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                {datas.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <p className="commitment__item">
                      <span>{item.icon}</span>
                      <span>{t(item.title)}</span>
                    </p>
                  </div>
                ))}
              </Box>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {datas
                  .sort((a, b) => a.index - b.index)
                  .map((item, index) => (
                    <div className="col-md-10" key={index} style={{margin: 'auto'}}>
                      <p className="commitment__item">
                        <span>{item.icon}</span>
                        <span>{t(item.title)}</span>
                      </p>
                    </div>
                  ))}
              </Box>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
