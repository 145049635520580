import React, { useEffect, useState } from "react";
import ART from "./step/art";
import Designs from "./step/designs";
import Info from "./step/info";
import Shape from "./step/shape";
import className from "classnames";
import Commitment from "./commitment";
import {
  IArtistInfos,
  IArtworksInfos,
} from "../../../models/form-design/form-design-models";
import { postArtworkFrom } from "../../../services/from-design/from-design.service";
import {
  WEBSITE_CONFIRMATION,
  WEBSITE_CONFIRMATION1,
} from "../../../constants/url-config";
import ErrorModal from "./modal/error-modal";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../../helpers/storage";
import Artist from "./step/artist";
import {RouteChildrenProps} from "react-router";
import * as queryString from "querystring";
import {convertToStringArray} from "../../../helpers/string";

interface IProps extends RouteChildrenProps {
}

export enum Steps {
  ARTIST,
  SHAPE,
  ART,
  INFO,
  DESIGNS,
}

function FromDesign(props: IProps): JSX.Element {
  const { t } = useTranslation();
  const [artworksFiles, setArtworksFiles] = useState<File[]>([]);
  const [artworksInfos, setArtworksInfos] = useState<IArtworksInfos[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [artistData, setArtistData] = useState({
    artistPersona: "",
    artistMainOriginalArtOutlet: "",
    artistHopesForWearableArt: "",
    howDidArtistKnow: ""
  });

  // If Silhouette is pre-selected in the URL, then we can assign it and script the first step
  const params = new URLSearchParams(props.location?.search);
  const paramShapes = convertToStringArray(params.get("silhouettes"));

  const [shapes, setShapes] = useState<string[]>(paramShapes);
  const [step, setStep] = useState<Steps>(paramShapes.length > 0 ? Steps.ART : Steps.SHAPE);

  const [stepDesign, setStepDesign] = useState<0 | 1>(0);

  const steps = [
    // {
    //   id: Steps.ARTIST,
    //   name: t("title.artist"),
    // },
    {
      id: Steps.SHAPE,
      name: t("title.shape"),
    },
    {
      id: Steps.ART,
      name: t("title.art"),
    },
    {
      id: Steps.INFO,
      name: t("title.info"),
    },
    {
      id: Steps.DESIGNS,
      name: t("title.designs"),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handleSelectArtistData = (artistHopesForWearableArt: string) => {
    setArtistData((pre) => {
      return {
        ...pre,
        artistHopesForWearableArt,
      };
    });

    setStep(Steps.SHAPE);
  };

  const handleSelectShapes = (datas: string[]) => {
    setShapes(datas);
    setStep(Steps.ART);
  };

  const handleSetArtworksInfo = (
    artworksFiles: File[],
    artworksInfos: IArtworksInfos[]
  ) => {
    setArtworksFiles(artworksFiles);
    setArtworksInfos(artworksInfos);
    setStep(Steps.INFO);

    setTimeout(() => {
      document.getElementById("inputContactFirstName")?.focus();
    });
  };

  const handleSetInfos = async (info: IArtistInfos) => {
    setStep(Steps.DESIGNS);
    await onSubmitData(info);
  };

  const changeFileName = (file: File) => {
    const blob = file.slice(0, file.size, file.type);

    const newFile = new File(
      [blob],
      file.name,
      { type: file.type }
    );

    return newFile;
  };

  const onSubmitData = async (info: IArtistInfos) => {
    let formData = new FormData();
    const language = getLanguage();

    for (let index = 0; index < 3; index++) {
      const file = artworksFiles[index];
      const artwork = artworksInfos[index];

      formData.append(`artwork${index + 1}`, file ? changeFileName(file) : "");
      formData.append(
        `artwork${index + 1}-name`,
        artwork
          ? artwork.name
          : ""
      );
      formData.append(
        `artwork${index + 1}-technique`,
        artwork ? artwork.technique : ""
      );
      formData.append(
        `artwork${index + 1}-style`,
        artwork ? artwork.style : ""
      );
    }

    formData.append("shape", shapes.join(","));
    formData.append("method", "uploadData");
    formData.append("previousArtworks", "false");
    formData.append("currentLanguage", language.toUpperCase());

    formData.append("artistPersona", artistData.artistPersona);
    formData.append(
      "artistHopesForWearableArt",
      artistData.artistHopesForWearableArt
    );
    formData.append(
      "artistMainOriginalArtOutlet",
      artistData.artistMainOriginalArtOutlet
    );
    formData.append("howDidYouHearAboutUs", artistData.howDidArtistKnow);

    for (const key in info) {
      if (info.hasOwnProperty(key)) {
        formData.append(key, info[key as keyof IArtistInfos]);
      }
    }

    try {
      await postArtworkFrom(formData)
        .then(() => {
          setStepDesign(1);
          window.location.href =
            language === "en" ? WEBSITE_CONFIRMATION : WEBSITE_CONFIRMATION1;
        })
        .catch((e) => {
          console.error('postArtworkFrom Axios Exception', e);
          setOpen(true);
        });
    } catch (e) {
      console.error('postArtworkFrom Outer Exception', e)
    }
  };

  return (
    <section id="from-design">
      <div className="container p-0">
        <div className="progress-container">
          {steps.map((s) => (
            <div
              className={className(
                "circle",
                step === s.id && "active",
                step > s.id && "done"
              )}
              id="progress"
              key={s.id}
            >
              <span>{s.name}</span>
            </div>
          ))}
        </div>

        <Artist
          hidden={step !== Steps.ARTIST}
          setArtistData={setArtistData}
          handleSelectArtistData={handleSelectArtistData}
        />
        <Shape hidden={step !== Steps.SHAPE} setShapes={handleSelectShapes}/>
        <ART
          hidden={step !== Steps.ART}
          handleSetArtworksInfo={handleSetArtworksInfo}
        />
        <Info hidden={step !== Steps.INFO} handleSetInfos={handleSetInfos}/>
        <Designs hidden={step !== Steps.DESIGNS} step={stepDesign}/>
      </div>
      <Commitment step={step}/>

      <ErrorModal
        open={open}
        handleClose={() => {
          setOpen(false);
          window.location.reload();
        }}
        title="Oops!"
        content="We’ve run into a problem. Please try again later."
      />
    </section>
  );
}

export default FromDesign;
