import {
  IArtistPersona,
  IDesignPackageRes,
  IFAQRes,
  IStyleRes,
  ITechniqueRes,
} from "../../models/form-design/form-design-models";
import { postAsync } from "../../utils/http-client";

import { ReactComponent as Painting } from "../../assets/icons/technique/Painting.svg";
import { ReactComponent as Drawing } from "../../assets/icons/technique/Drawing.svg";
import { ReactComponent as Photography } from "../../assets/icons/technique/Photography.svg";
import { ReactComponent as Digital } from "../../assets/icons/technique/Digital.svg";
import { ReactComponent as Collage } from "../../assets/icons/technique/Collage.svg";
import { ReactComponent as Writing } from "../../assets/icons/technique/Writing.svg";
import { ReactComponent as Sculpture } from "../../assets/icons/technique/Sculpture.svg";
import { ReactComponent as Printmaking } from "../../assets/icons/technique/Printmaking.svg";

import { ReactComponent as Abstract } from "../../assets/icons/styles/Abstract.svg";
import { ReactComponent as BlackWhite } from "../../assets/icons/styles/Black_White.svg";
import { ReactComponent as Classic } from "../../assets/icons/styles/Classic.svg";
import { ReactComponent as FineArt } from "../../assets/icons/styles/Fine_Art.svg";
import { ReactComponent as Landscape } from "../../assets/icons/styles/Landscape.svg";
import { ReactComponent as Nature } from "../../assets/icons/styles/Nature.svg";
import { ReactComponent as PatternsTexture } from "../../assets/icons/styles/Patterns_texture.svg";
import { ReactComponent as PopArt } from "../../assets/icons/styles/Pop_Art.svg";
import { ReactComponent as Portrait } from "../../assets/icons/styles/Portrait.svg";
import { ReactComponent as Text } from "../../assets/icons/styles/Text.svg";
import { ReactComponent as UrbanArt } from "../../assets/icons/styles/Urban_Art.svg";
import DownToEarth from "../../assets/images/DownToEarth.png";
import Extravagant from "../../assets/images/Extravagant.png";
import Sophisticated from "../../assets/images/Sophisticated.png";
import Urban from "../../assets/images/Urban.png";
import ACTIVE_WEAR from "../../assets/images/ACTIVEWEAR.jpg";
import FASHIONFORWARD from "../../assets/images/FASHIONFORWARD.jpg";
import BESTSELLER from "../../assets/images/BESTSELLER.jpg";
import HOME_FASHION from "../../assets/images/HOME_FASHION.jpg";
import FASHION_LUXE from "../../assets/images/FASHION_LUXE.png";



export const getDesignPackage = (): IDesignPackageRes[] => {
  const designPackages: IDesignPackageRes[] = [
    {
      id: 5,
      name: "package.Activewear",
      avatarUrl: ACTIVE_WEAR,
      shapes: [
        "WPA12",
        "MLS08",
        "WSS16",
        "WNS10",
        "WNS11",
        "WLS27",
        "UAC15",
        "MLS07",
        "SJE01",
        "SKC01",
        "SWC01",
        "SIY01",
        "SLI01",
        "SSK01",
      ]
    },
    {
      id: 6,
      name: "package.FashionForward",
      avatarUrl: FASHIONFORWARD,
      shapes: [
        "WAC02",
        "WSS20",
        "WDR51",
        "WDR38",
        "WNS11",
        "WBL14",
        "WPA14",
        "WBL18",
        "ACF01",
        "RES01",
        "SJE01",
        "SKC01",
        "SWC01",
        "SIY01",
        "SLI01",
        "SSK01",
      ],
    },
    {
      id: 7,
      name: "package.BestSeller",
      avatarUrl: BESTSELLER,
      shapes: [
        "WBL06",
        "WAC02",
        "UAC65",
        "WDR26",
        "WDR40",
        "WLS13",
        "WLS10",
        "WPA03",
        "MSS07",
        "UAC69",
        "SJE01",
        "SKC01",
        "SWC01",
        "SIY01",
        "SLI01",
        "SSK01",
      ],
    },
    {
      id: 8,
      name: "package.HomeFashionAccess",
      avatarUrl: HOME_FASHION,
      shapes: [
        "WBL14",
        "WAC09",
        "UAC61",
        "WAC02",
        "UAC64",
        "UAC67",
        "UAC65",
        "ACF02",
        "UAC63",
        "TWL01",
        "TWL03",
        "SJE01",
        "SKC01",
        "SWC01",
        "SIY01",
        "SLI01",
        "SSK01",
      ]
    },
    {
      id: 9,
      name: "package.LuxuriousFashion",
  avatarUrl: FASHION_LUXE,
    shapes: [
      "WBL20",
      "WAC02",
      "WBL17",
      "ACF01",
      "WDR58",
      "WDR56",
      "WAC14",
      "WDR38",
      "WBL14",
      "WBL18",
      "SJE01",
      "SKC01",
      "SWC01",
      "SIY01",
      "SLI01",
      "SSK01",
]}];
  return designPackages;
}

export const getTechniques = (): ITechniqueRes[] => {
  const techniques: ITechniqueRes[] = [
    {
      id: "Painting",
      name: "formRequestNewDesign.modalUploadArtwork.painting",
      icon: Painting,
    },
    {
      id: "Drawing",
      name: "formRequestNewDesign.modalUploadArtwork.drawing",
      icon: Drawing,
    },
    {
      id: "Photography",
      name: "formRequestNewDesign.modalUploadArtwork.photography",
      icon: Photography,
    },
    {
      id: "Digital",
      name: "formRequestNewDesign.modalUploadArtwork.digital",
      icon: Digital,
    },
    {
      id: "Collage",
      name: "formRequestNewDesign.modalUploadArtwork.collage",
      icon: Collage,
    },
    {
      id: "Writing",
      name: "formRequestNewDesign.modalUploadArtwork.writing",
      icon: Writing,
    },
    {
      id: "Sculpture",
      name: "formRequestNewDesign.modalUploadArtwork.sculpture",
      icon: Sculpture,
    },
    {
      id: "Printmaking",
      name: "formRequestNewDesign.modalUploadArtwork.printmaking",
      icon: Printmaking,
    },
  ];

  return techniques;
};
export const getStyles = (): IStyleRes[] => {
  const styles: IStyleRes[] = [
    {
      id: "Fine Art",
      name: "formRequestNewDesign.modalUploadArtwork.fineArt",
      icon: FineArt,
    },
    {
      id: "Portrait",
      name: "formRequestNewDesign.modalUploadArtwork.portrait",
      icon: Portrait,
    },
    {
      id: "Abstract",
      name: "formRequestNewDesign.modalUploadArtwork.abstract",
      icon: Abstract,
    },
    {
      id: "Black & White",
      name: "formRequestNewDesign.modalUploadArtwork.blackWhite",
      icon: BlackWhite,
    },
    {
      id: "Landscape",
      name: "formRequestNewDesign.modalUploadArtwork.landscape",
      icon: Landscape,
    },
    {
      id: "Pop Art",
      name: "formRequestNewDesign.modalUploadArtwork.popArt",
      icon: PopArt,
    },
    {
      id: "Classic",
      name: "formRequestNewDesign.modalUploadArtwork.classic",
      icon: Classic,
    },
    {
      id: "Nature",
      name: "formRequestNewDesign.modalUploadArtwork.nature",
      icon: Nature,
    },
    {
      id: "Text",
      name: "formRequestNewDesign.modalUploadArtwork.text",
      icon: Text,
    },
    {
      id: "Patterns & Textures",
      name: "formRequestNewDesign.modalUploadArtwork.patternsTexture",
      icon: PatternsTexture,
    },
    {
      id: "Urban Art",
      name: "formRequestNewDesign.modalUploadArtwork.urban",
      icon: UrbanArt,
    },
  ];

  return styles;
};

export const postArtworkFrom = (params: FormData) => {
  const url = "nop/api/artwork-forms/submit-form";

  return postAsync(url, params, "multipart/form-data");
};

export const checkEmailRegisteredAsync = (email: string) => {
  const url = "nop/api/artists/check-email-duplicate";

  return postAsync(url, { email });
};

export const getFAQ = (): IFAQRes => {
  const topFAQ: Array<{title: string, description: string}> = [];

  const bottomFAQ = [
    {
      title: "faq.ImintheUSand",
      description: "faq.NoOurproducts",
    },
    {
      title: "faq.WillIkeep",
      description: "faq.Youretain",
    },
    {
      title: "faq.Whyshould",
      description: "faq.Wecomplyto",
    },
    {
      title: "faq.Doyouintegrate",
      description: "faq.Youmaysell",
    },
    {
      title: "faq.WillIneed",
      description: "faq.OurfreeStorefront",
    },
  ];

  return {
    topFAQ,
    bottomFAQ,
  };
};

export const getArtistPersona = (): IArtistPersona[] => {
  const techniques: IArtistPersona[] = [
    {
      id: 1,
      name: "artistStep.downToEarth",
      value: "Down to Earth",
      avatarUrl: DownToEarth,
    },
    {
      id: 2,
      name: "artistStep.sophisticatedChic",
      value: "Sophisticated & Chic",
      avatarUrl: Sophisticated,
    },
    {
      id: 3,
      name: "artistStep.extravagantWild",
      value: "Extravagant & Wild",
      avatarUrl: Extravagant,
    },
    {
      id: 4,
      name: "artistStep.urbanTrendy",
      value: "Urban & Trendy",
      avatarUrl: Urban,
    },
  ];

  return techniques;
};

export const getArtistMainOriginalArtOutlet = (): {
  id: number;
  name: string;
  value: string;
}[] => {
  const techniques: { id: number; name: string; value: string; }[] = [
    {
      id: 1,
      name: "artistStep.iOwnAGalleryStore",
      value: "Gallery store"
    },
    {
      id: 2,
      name: "artistStep.wordofmouthFriendsFamily",
      value: "Family and Friends"
    },
    {
      id: 3,
      name: "artistStep.onMyOwnWebsite",
      value: "On Personal Website"
    },
    {
      id: 4,
      name: "artistStep.onOtherWebsitesmarketplaces",
      value: "Marketplaces"
    },
    {
      id: 5,
      name: "artistStep.socialNetworks",
      value: "Social Networks"
    },
    {
      id: 6,
      name: "artistStep.artEvents",
      value: "Art Events"
    },
    {
      id: 7,
      name: "artistStep.iDonnotSellMyArt",
      value: "I don't sell art"
    },
  ];

  return techniques;
};

export const getArtistHopesForWearableArt = (): {
  id: number;
  name: string;
  value: string;
}[] => {
  const techniques: { id: number; name: string; value: string; }[] = [
    {
      id: 1,
      name: "artistStep.iamJustCurious",
      value: "Just curious"
    },
    {
      id: 2,
      name: "artistStep.iLoveToWearMyArtAndIThinkMyFamilyAndFriendsMightLikeItToo",
      value: "Personal Shopping, Friends and Family"
    },
    {
      id: 3,
      name: "artistStep.iamActuallyThinkingOfHavingMyFullblownFashionLine",
      value: "Launch a fashion line"
    },
  ];

  return techniques;
};
